<template>
  <div class="shop-goods-list-page">
    <en-table-layout :tableData="pageData.data" @selection-change="handleSelectionChange">
      <!-- <div
        slot="header"
        style="padding:15px 15px 0 15px;"
      >
        <el-form
          inline
          class="row no-gutters align-items-center"
        >

        </el-form>

      </div> -->
      <template slot="toolbar">

        <el-form-item label="状态" class="col-auto" size="small">
          <el-select v-model="params.status" placeholder="请选择" clearable style="width:110px;" @change="search"
            size="small">
            <el-option label="全部" value=""></el-option>
            <el-option label="禁用" :value="0"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <div class="col-auto">
          <el-input style="width: 200px;" size="small" placeholder="请输入卡券名称" v-model="params.card_name" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div class="col-auto ml-2">
          <el-button @click="search" size="small" type="primary">
            搜索
          </el-button>
        </div>

        <div class="col"></div>
        <div class="col-auto">


          <el-checkbox v-model="isAllChecked" :true-label="1" :false-label="0" style="margin-right:10px;">全选</el-checkbox>

          <el-button size="small" @click="exportQrCode" type="primary">
            导出勾选选项二维码
          </el-button>

          <el-button @click="handleAddCard" type="primary" size="small">
            添加
          </el-button>

        </div>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" />

        <el-table-column :key="'code'" label="编号" width="50" fixed="left">
          <template slot-scope="scope">
            {{ code(scope.$index, params) }}
          </template>
        </el-table-column>

        <el-table-column label="卡券二维码" width="100" fixed="left" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.qr_img_url" alt="" style="width: 300px">
              <img :src="row.qr_img_url" class="goods-cover" alt="" slot="reference" />
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="卡券名称" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="scope">
            {{ scope.row.card_name }}
          </template>
        </el-table-column>

        <el-table-column label="卡券价位" width="80" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.card_value }}
          </template>
        </el-table-column>
        <el-table-column label="有效期" width="80" prop="price">
          <template slot-scope="scope">
            {{ scope.row.validity_year }}年
          </template>
        </el-table-column>

        <el-table-column label="总数" width="80">
          <template slot-scope="scope">
            {{ scope.row.key_num }}
          </template>
        </el-table-column>

        <el-table-column label="已激活数" width="80">
          <template slot-scope="scope">
            <span class="num" @click="handleClickNum(scope.row, 'activeNum')">{{
              scope.row.active_num
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="已兑换数" width="80">
          <template slot-scope="scope">
            <span class="num" @click="handleClickNum(scope.row, 'usedNum')">{{
              scope.row.use_num
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="已过期数" width="80" prop="expire_num">
          <template slot-scope="scope">
            <span class="num" @click="handleClickNum(scope.row, 'expNum')">{{
              scope.row.expire_num
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.status | cardStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="250">
          <template slot-scope="scope">

            <el-button size="mini" type="danger" v-if="scope.row.status === 1" @click="handleNoUseCard(scope.row)">
              禁用
            </el-button>
            <el-button size="mini" type="primary" v-if="scope.row.status === 0" @click="handleUseCard(scope.row)">
              启用
            </el-button>
            <el-button size="mini" type="primary" @click="handleEditCard(scope.row)">编辑
            </el-button>
            <el-button size="mini" type="primary" @click="handleGrant(scope.row)">发放
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_Card from "@/api/combo-card-shop/card";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import mixin from "./components/mixin";

export default {
  name: "cardList",
  mixins: [mixin],
  components: {
    EnTableLayout,
  },
  data () {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 绑卡记录列表loading状态 */
      comboRecordsLoading: false,
      idsList: [],
      isAllChecked: false,

      showDialog: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        status: "",
        card_name: "",
      },

      /** 列表分页数据 */
      pageData: {},
      /**是否是企业跳转过来 */
      parentLogin: 0,
    };
  },
  filters: {
    /** 礼包状态格式化 */
    cardStatus (status) {
      switch (status) {
        case 0:
          return "禁用";
        case 1:
          return "启用";
      }
    },
  },
  created () {
    API_goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
    });
  },
  activated () {
    this.GET_CardList();
  },
  mounted () {
    this.GET_CardList();
  },
  methods: {
    handleGrant (row) {
      this.$router.push({ name: 'cardBatchComboCard', params: { card_id: row.id } })
    },
    handleClickNum (row, numType) {
      numType === 'activeNum' && this.$router.push({ name: 'numActiveListComboCard', params: { card_id: row.id } })
      numType === 'usedNum' && this.$router.push({ name: 'numUsedListComboCard', params: { card_id: row.id } })
      numType === 'expNum' && this.$router.push({ name: 'numExpListComboCard', params: { card_id: row.id } })
    },
    /**
     * 添加套餐
     */
    handleAddCard () {
      this.$router.push({
        name: "cardEditComboCard"
      });
    },
    /**
     * 导出勾选选项二维码
     */
    exportQrCode () {
      if (!this.isAllChecked && !this.idsList.length) {
        this.$message.error('请勾选要导出二维码图片的卡券。')
        return
      }
      let _params = {
        ids: this.idsList,
        is_all: this.isAllChecked ? 1 : 0,
        // sign: 1,// 1:在线发放，2:卡密管理
        active: this.params.status,
        keyword: '',
        card_name: this.params.card_name
      };

      API_Card.qrExport(_params).then(res => {
        this.$message.success('导出成功。')
        this.save(res)
      })
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_CardList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_CardList();
    },

    search () {
      this.params.page_no = 1;
      this.GET_CardList();
    },

    /** 禁用*/
    handleNoUseCard (row) {
      this.$confirm(
        "确定要禁用这个卡券吗？",
        "提示",
        {
          type: "warning",
        }
      ).then(() => {
        API_Card.changeStatus({ id: row.id, status: 0 }).then(() => {
          this.$message.success("禁用成功");
          this.GET_CardList();
        });
      });
    },

    /** 启用*/
    handleUseCard (row) {
      this.$confirm("确定要启用这个卡券吗？", "提示", {
        type: "warning",
      }).then(() => {
        API_Card.changeStatus({ id: row.id, status: 1 }).then(() => {
          this.$message.success("启用成功");
          this.GET_CardList();
        });
      });
    },

    GET_CardList () {
      this.loading = true;
      API_Card.getCardList(this.params).then((response) => {
        if (response.data && response.data.length === 0 && response.page_no > 1) {
          this.params.page_no--;
          return this.GET_CardList();
        }

        this.loading = false;
        this.pageData = response;
      });
    },

    /** 编辑礼包*/
    handleEditCard (row) {
      this.$router.push({
        name: "cardEditComboCard",
        params: {
          id: row.id,
          callback: this.GET_CardList,
        },
      });
    },

    handleSelectionChange (val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });

      this.idsList = ids;
    },
  },
};
</script>

<style lang="scss" scoped>
.num {
  cursor: pointer;
  color: #2155d5;
  text-decoration: underline;
}

.num:hover {
  text-decoration: underline;
}
</style>
